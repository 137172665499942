@use 'styles/includes' as *;

.InlineImage {
    $root: &;

    &--Black {
        color: $white;
        background-color: $black;
    }

    &__Container {
        @extend %container;

        #{$root}--Bleed & {
            padding: 0;
            max-width: none;
        }
    }

    &__Figure {
        margin: 0 auto;

        #{$root}--Default & {
            max-width: 63rem;
        }

        #{$root}--Outside & {
            max-width: 69rem;
        }
    }

    &__Image {
        position: relative;
    }

    &__Text {
        @extend %caption;
        margin: 1rem auto 0 auto;

        i {
            font-style: italic;
        }

        b {
            font-weight: $font-weight-bold;
        }

        #{$root}--Bleed & {
            padding: 0 2rem;
            max-width: 75rem;

            @include media(s) {
                padding: 0 3rem;
            }
        }

        #{$root}--Outside & {
            max-width: 69rem;
        }

        #{$root}--Default & {
            max-width: 63rem;
        }
    }
}
